<template>
  <div class="vodList">
    <div class="vodWrap flexL">
      <div class="video-list" :class="{'active':selectId == data.contentsId}" v-for="(data, i) in vodList" :key="i" @click="getVod(data.contentsId, data.eventId)">
        <button class="videoBtn"></button>
        <div class="thumBox">
          <img :src="baseUrl + data.thumbnailUri" />
          <div class="duration">
            <span class="duration">{{ changeDuration(data.duration) }}</span>
          </div>
        </div>
        <div class="vod-list-title">
          <span class="TitleVod vodName">{{ data.title }}</span>
          <!-- <h2>{{data.eventTitle}}</h2> 개발 필요 20240126 -->
        </div>
        <span class="textLimit" style="width: 100%; margin-top: 2px; display: flex;">
          <span class="small" style="max-width: 70px; margin-right: 8px;">{{ data.deviceType != 'Mobile' ? data.deviceName : $t(getDeviceTypeName(data.deviceType)) }}</span>
          <span class="small" style="margin-right: 8px;">{{ data.registerUserName }}</span>
          <span class="small" style="margin-right: 0;">{{ data.registerDepartmentName }}</span>
        </span>
        <p class="bold flexL" style="margin-top: 0; ">
          <span class="vodName">{{moment(data.registerDate).format("YYYY.MM.DD HH:mm")}}</span>
        </p>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
          small
          layout="prev, pager, next"
          :page-size="size"
          @current-change="handleCurrentChange"
          :total="total"
          :current-page="currentPage + 1"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style>
div.duration {
  position: absolute;
  width: 36px;
  height: 18px;
  right: 12px;
  bottom: 9px;
  padding: 1px 4px 2px 5px;
  background-color: rgba(30, 30, 30, 0.5);
}
.textLimit>.small{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
span.duration {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.TitleVod{
  display: block;
  width: 60%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.vodName{
  font-size: 1.2rem;
  font-weight: normal;
  color: #808080;
}
.vod-list-title{
  margin-top: 10px;
}
.vod-list-title > span{
  padding: 2px 0px;
  border-radius: 5px;
  color: #0080ff !important;
  width: 110px;
  text-align: left;
  font-size: 1.2rem;
}
.vod-list-title > h2{
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
<script>
import moment from "moment";
import { baseUrl } from "@/config/env";
import { fetchVodSrc } from "@/api/file";
import { fetchVodList } from "@/api/contents";

export default {
  props: {
    eventId: {
      type: String,
      default: "",
    },
    vodUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      baseUrl: baseUrl,
      currentPage: 0,
      vodList: [],
      total: 0,
      size: 8,
      selectId : null,
      scr: "",
    };
  },
  mounted() {
    this.getContentsList();
    
  },
  methods: {
    changeDuration(seconds) {
      if(seconds == null) {
        return "00:00";
      }
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return min+":" + sec;
    },
    async getContentsList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        eventId: this.eventId,
        deviceType : "Drone,Mobile"
      };
      if (!this.eventId) {
        delete params.eventId;
      }

      await fetchVodList(params).then((res) => {
        this.total = res.data.data.total;
        this.vodList = res.data.data.content;

        this.getVod();
        
      });
    },
    getDeviceTypeName(type){
      var result = ""
      switch (type){
        case "Drone":{
          result = "드론";
        }
        break;
        case "Mobile":{
          result = "휴대폰";

        }
        break;
      }
      return result;
    },
    getVod(contentsId = null, eventId = null) {
      this.selectId = null;
      if (contentsId == null) {
        contentsId = this.vodList[0].contentsId;
      }
      this.selectId = contentsId;
      fetchVodSrc(contentsId).then((res) => {
        if (res.data.result == 0) {
          let data = {
            src: this.vodUrl + res.data.data.streamUri,
            eventId: eventId,
            contentsId: contentsId,
          };
          if (res.data.data.streamUri == undefined) {
            data.src = "";
          }
          if (!eventId) {
            delete data.eventId;
          }
          this.$emit("viewVideo", data);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getContentsList();
    },
  },
};
</script>
