<template>
  <div class="contents allVod" >
    <div class="flexB" style="display: flex; align-items: stretch;margin-bottom: 15px;">
      <div class="video-list" style="position: relative; width:60%;">
        <div class="video-info-box" id="video-info-box" v-if="vodObj.contentsId != undefined">
          <span>{{ vodObj.deviceName == null ? '휴대폰 ' : vodObj.deviceName }}</span>
          <span>│</span>
          <span> {{changePlayTime == null ? ' -' : changePlayTime}}</span>
        </div>
        <videoPlayer ref="video" :src="viewSrc" @onTimeUpdate="onTimeUpdate" @onUpdateAiDetecting="onUpdateAiDetecting" :playerId="'isplay'" :isUser="userType == 'User'" :contentsId="contentsId" :isAiMarkShow="true" @contents-delete="onContentsDelete" @isCanPlay="playerLoadData" @isReadyPlay="playerReady"></videoPlayer>
      </div>
      <div class="mapWrap" style="width:39%;">
        <div class="mapWrap-title">
          <h2>비행 현장 정보</h2>
          <button class="point" @click="playerOpen" style="padding: 0px; width: 110px; height: 25px;">{{ $t("btn-vod-new-browser") }}</button>
        </div>
        <div class="map" style="height: 58%;">
          <Map ref="map" style="width: 100%; height: 100%; border: 1px solid #d3d3d3;" :loadMapName="loadMap" :isLeftButtonPosition="false" @play-marker-lng-lat="playMarkerLngLat" @map-move-center="onMapCenter"/>
          <span v-if="locationSize == 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
            {{$t("popup-location-not-found")}}
          </span>
        </div>

        <div class="mapInfo" >
          <div class="bottom_sc" v-if="viewSrc">
            <div class="infoData">
              <span v-if="vodObj.contentsId != undefined" style="margin-right: 0;">
                  <!-- 20240117 update -->
                <div class="vodInfo flexL" style="flex-direction: column;">
                  <div class="vod-site-info">
                    <div class="vod-site" style="width: inherit; max-width: 510px; justify-content: space-between !important; margin-bottom: 0;" ><!--:style="{'width': droneValWidth + 'px'}">-->
                        <span class="vodData grayW vod-number">{{ vodObj.title? vodObj.title : 'N/A' }}</span>
                        <h2 class="vod-site-title">{{vodObj.eventName}}</h2> <!-- 개발 필요 20240126 -->
                        <span class="vodData" style="border-right: 1px solid #ddd; padding-right:5px">{{ vodObj.registerUserName? vodObj.registerUserName : 'N/A' }}</span>
                        <span class="small vodData" style="border-right: 1px solid #ddd; padding-right:5px">{{ vodObj.registerUserDeptName? vodObj.registerUserDeptName : 'N/A' }}</span>
                        <span class="small vodData" style="margin-right: 0;">{{ moment(vodObj.registerDate).format("YYYY.MM.DD HH:mm") }}</span>
                    </div>
                    <div class="vod-device">
                      <div class="vod-device-info">
                        <span class="vodTitle"><img src="@/assets/images/icon_svg/device_icon.png" alt="icon" />{{$t("device-data-device-name")}}</span>
                        <span class="bold small vodData">{{ vodObj.deviceName == null ? '휴대폰' : vodObj.deviceName }}</span>
                      </div>
                      <div class="vod-device-info"> <!--:style="isdrModelShow? 'visibility: visible':'display:none'">-->
                        <span class="vodTitle"><img src="@/assets/images/icon_svg/model_icon.png" alt="icon" />{{$t("device-data-model-name")}}</span>
                        <span class="bold small vodData textLimit" :style="isdrModelShow? 'max-width:360px':'max-width:160px'">{{ vodObj.deviceModelName == null ? '휴대폰' : vodObj.deviceModelName }}</span>
                      </div>
                    </div>
                  </div>
                  <div id="drInfoBox" class="flex droneVal">
                    <div class="droneVal-row">
                      <div class="droneData flex">
                        <span class="bold DataName">고도</span>
                        <span class="vodData">{{ doneData.height == null ? 'N/A' : `${doneData.height.toFixed(0)} m` }}</span>
                      </div>
                      <div class="droneData flex">
                        <span class="bold DataName">거리</span>
                        <span class="vodData">{{ doneData.distance == null ? 'N/A' : `${doneData.distance.toFixed(0)} m` }}</span>
                      </div>
                      <div class="droneData flex">
                        <span class="bold DataName">수평</span>
                        <span class="vodData">{{doneData.hspeed == null ? 'N/A' :  `${doneData.hspeed.toFixed(0)} m/s`}}</span>
                      </div>
                      <div class="droneData flex">
                        <span class="bold DataName">수직</span>
                        <span class="vodData">{{ doneData.vspeed == null ? 'N/A' : `${doneData.vspeed.toFixed(0)} m/s` }}</span>
                      </div>
                    </div>
                    <div class="droneVal-row">
                      <div class="droneData flex">
                        <span class="DataName bold">위도</span>
                        <span class="vodData" style="margin-right: 0; width: 90px; text-align: end;">{{lat == null ?  'N/A': `${lat.toFixed(9)}`}}</span>
                      </div>
                      <div class="droneData flex">
                        <span class="DataName bold">경도</span>
                        <span class="vodData" style="margin-right: 0; width: 90px; text-align: end;">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <!--            <button  @click="playerOpen">{{ $t("btn-vod-new-browser") }}</button>-->
          </div>
        </div>
      </div>
    </div>
    <div class="box one vodlist">
      <div class="box one filter" style="display: flex;justify-content: flex-start;align-items: center;padding:0px 0px 5px 0px;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <!-- <option value="">VOD ID</option> -->
            <option value="title">{{ $t("vod-data-title") }}</option>
            <!--          <option value="eventViewId">{{ $t("event-data-site-num") }}</option>-->
            <!--          <option value="eventName">{{ $t("event-data-site-name") }}</option>-->
            <option value="registerUserName">{{ $t("live-data-channel-operator") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search()"
          />
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 0;">
            <button class="point medium" @click="search()" style="width: 120px; margin-left: 20px;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:300;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px;border-color:#ddd;color:#969696; font-weight:300;border-radius:5px;">
              {{ $t("btn-reset") }}
            </button>
        </div>
      </div>
      <!--      <h2 class="bold">{{ $t("vod-list-title") }}</h2>-->
      <VodList ref="vodList" :vodUrl="vodUrl" :eventId="eventId" @viewVideo="viewVideo"  />
    </div>
  </div>
</template>
<style>
.mapWrap{
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.mapWrap > .mapWrap-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.mapWrap > .mapWrap-title > h2{
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
}
.mapWrap > .mapWrap-title > button{
  background: #fff;
  border: 1px solid #0080FF;
  border-radius: 5px ;
  color: #0080FF;
  font-weight: 400;
}
.vodTitle{
  color: #808080;
  font-size: 1.2rem;
}
.grayW{
  color: #808080;
}
.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
}
.DataName{
  color: #6ebeff;

}
.vod-site-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.vod-site > .vodData{color: #969696;}
.vod-number{
  padding: 3px 0px;
  border-radius: 5px;
  color: #0080ff !important;
}
.vod-site-title{
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.vod-device-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}
.vod-device-info:nth-child(2){
  margin-bottom: 0px;
}
.vod-device-info > span:nth-child(2){
  color: #454545;
  font-size: 1.2rem !important;
  font-weight: 400;
}
.vod-device{
  width: 250px;
}
.vod-device-info > .vodTitle > img{margin-right: 5px;vertical-align: baseline;}
.droneVal{display: flex;flex-direction: column;}
.droneVal > .droneVal-row{display: flex;justify-content: space-between;align-items: center;}
.droneVal > .droneVal-row:nth-child(1){padding-top: 15px;margin-bottom: 15px;}
.droneVal > .droneVal-row > .droneData{display: flex;justify-content: space-between;align-items: center;}
.droneVal > .droneVal-row:nth-child(1) > .droneData:nth-child(1){width: 25%;}
.droneVal > .droneVal-row:nth-child(1) > .droneData:nth-child(2){width: 25%;}
.droneVal > .droneVal-row:nth-child(1) > .droneData:nth-child(3){width: 25%;}
.droneVal > .droneVal-row:nth-child(1) > .droneData:nth-child(4){width: 25%;}
.droneVal > .droneVal-row:nth-child(2) > .droneData:nth-child(1){width: 50%;}
.droneVal > .droneVal-row:nth-child(2) > .droneData:nth-child(2){width: 50%;}
.droneVal > .droneVal-row > .droneData > .DataName{background: #eee;color: #969696;padding: 3px 15px;border-radius: 5px;}

.vodlist > h2{
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
/*사진캡처 버튼*/
.imgCapt{
  width: 96px;
  height: 36px;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 2000;
  margin: 10px;
  padding: 6px 10px;
  color:black;
  border: 1px solid white;
}
.imgCapt>span{
  line-height: 23px;
  font-weight: bold;
  font-size: 1.2rem;
}
.imgCapt:hover{
  background: #94d9ef;
}
.vod-ai-mark{position: absolute;z-index: 10;left: 10px;top: 10px;background: #0b0a11b5;padding: 11px 10px;display: flex;justify-content: space-between;align-items: center;border-radius: 5px;}
.vod-ai-mark > .vod-ai-btn{display: flex;align-items: center;}
.vod-ai-mark > .vod-ai-btn > label{font-size: 1.2rem;color: #fff; margin-right: 10px;}
/* .vjs-tech{object-fit: cover;} */
.video-player .video-js {
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    border-radius: 5px;
}
.video-info-box{position: absolute;top: 10px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 5px 20px;color: #fff;border-radius: 5px;z-index: 100;}
.video-info-box > span{font-size: 1.4rem;}
</style>

<script>
import moment from "moment";
import videoPlayer from "@/components/video/videoPlayer";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import VodList from "./VodList";
import { fetchVodUrlList } from "@/api/server";
import { fetchLocation } from "@/api/location";
import { fetchVodDetail, fetchVodList } from "@/api/contents";
import { fetchEvent} from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {playerPopOpen} from "@/util/popup";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import {ko} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  components: { VodList, videoPlayer, Map , Datepicker},
  name: "Main",
  props: {
    eventId: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //index: 0,
      moment: moment,
      loadMap : 'kakao',
      viewSrc: "",
      vodUrl: "",

      isdrModelShow:true,
      eventInfo : null,
      contentsId: null,
      vodObj: {},
      location: [],
      lat : null,
      lng : null,
      locationSize : null,
      isPlayStatus: false,
      doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
        heartRate : null,
      },
      ko: ko,
      startYmd: null,
      endYmd: null,
      currentPage: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      total: 0,
      vodList: [],

      windowWidth: null,
      changePlayTime : null,
      videoKey : 0 ,
    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
    }),
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    windowWidth(){
      this.windowWidth = window.innerWidth;
      this.handleResize()
    }
  },
  mounted() {
    // await this.getSDList();
    this.getEvent();
    this.getVodList();
    this.initMapData(this.$store.state.store.currentLang );
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    onContentsDelete(){
      this.$refs.vodList.getContentsList()();
    },
    handleResize(){
      this.windowWidth = window.innerWidth;
      if(this.windowWidth < 1650){
        this.isdrModelShow = false;
      }else{
        this.isdrModelShow = true;
      }

    },
    playerLoadData(id, isLoad) {
      this.isPlayStatus = isLoad;
    },
    playerReady(player){
      player
      if(this.vodObj.contentsId != undefined) {
        this.$refs.video.onPlayerAddDom("video-info-box");
      }

    },
    initDoneData(){
      this.doneData.distance = null;
      this.doneData.hspeed = null;
      this.doneData.vspeed = null;
      this.doneData.height = null;
      this.doneData.battery = null;
      this.doneData.heartRate = null;
    },
    playMarkerLngLat(lng,lat,index) { //index로 vod 부분에서 꺼내 사용하는 데이터 번호 넘겨주기.
      this.$refs.map.onSetLngLat(lng,lat);
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.doneData.distance = locationData.distance;
        this.doneData.hspeed = locationData.hspeed;
        this.doneData.vspeed = locationData.vspeed;
        this.doneData.height = locationData.height;
        this.doneData.battery = locationData.battery;
        this.doneData.heartRate = locationData.heartRate;
        this.lng = lng;
        this.lat = lat;
      }
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getEventPoint();
      this.setMapSetting();
      if(this.contentsId != null) {
        this.getLocation(this.contentsId);
      }
    },
    setMapSetting(){
      this.$refs.map.onShowCenterPoint();
      this.$refs.map.onShowButtonTypeChange();
      //this.$refs.map.onShowLngLat();
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          if(this.$refs.map == undefined) {
            return;
          }
          if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
      });
    },
    onMapCenter() {
      if (this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.lng = this.eventInfo.lng;
        this.lat = this.eventInfo.lat;
        this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    onTimeUpdate(time) {
      if(this.vodObj != null) {
        let changeTIme = moment(new Date(this.vodObj.recordDate)).add('milliseconds', time* 1000 );
        this.changePlayTime = changeTIme.format("YYYY-MM-DD HH:mm:ss");
      }
      if(this.locationSize > 0) {
        this.$refs.map.onPlayUpdatePosition(time);
      }
    },
    async getLocation(contentsId){
      this.location = [];
      this.initDoneData();

      this.$refs.map.onPlayRemoveData()
      this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
      let params = {
        contentsId: contentsId,
      };
      this.locationSize = null;
      await fetchLocation(params).then((res) => {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        if (res.data.result == 0) {
          this.locationSize = res.data.data.content.length;
          if( this.locationSize == 0) {
            return;
          }
          this.location = res.data.data.content.sort(function(a, b) {
            return a.index - b.index;
          })
          var first = res.data.data.content[0];
          this.lng = first.lng;
          this.lat = first.lat;
          this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
          var mapLineData = new MapLineData();
          mapLineData.id = this.contentsId;
          mapLineData.locationGroup = res.data.data.content;
          mapLineData.mapDataType = this.vodObj.deviceName == null ? MapDataType.ID_STREAMER : MapDataType.ID_DRONE;
          this.$refs.map.onPlayCreateData(mapLineData);
        } else {
          this.locationSize = null;
        }
      });
    },
    async viewVideo(data) {
      this.viewSrc = data.src;
      this.isPlayStatus = false;

      fetchVodDetail(data.contentsId).then((res) => {
        if(res.data.result == 0) {
          this.videoKey++
          this.vodObj = res.data.data;
          this.selectContentsId = this.vodObj.contentsId;

          let changeTime = moment(new Date(this.vodObj.recordDate))
          this.changePlayTime = changeTime.format("YYYY-MM-DD HH:mm:ss");
        }

        if(this.contentsId != data.contentsId) {
          this.contentsId = data.contentsId;
          this.getLocation(this.contentsId);
        }
      });

    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getVodList();
    },
    getVodList() {
      if(this.$refs.map != undefined) {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      }

      fetchVodUrlList(this.eventId).then((res) => {
        this.vodUrl = res.data.data.properties.vodStreamUrl + "/" + res.data.data.properties.application;
      });

      let params = {
        pageNumber: this.currentPage,
        pageSize: 8,
        eventId: this.eventId,
        deviceType: 'Drone,Mobile',
        keyword: this.keyword,
        keywordType: this.keywordType,
        eventStatus: 'Open',
      };
      if (this.startYmd && this.endYmd) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["registeredDateRange.from"] = from;
        params["registeredDateRange.to"] = to;
      }

      fetchVodList(params).then((res) => {
        this.vodList = res.data.data.content;
        this.$refs.vodList.vodList = res.data.data.content;
        this.$refs.vodList.total = res.data.data.total;
      });
    },
    playerOpen() {
      playerPopOpen("vod",this.contentsId);
    },
    reset(){
      this.currentPage = 0;
      this.keyword = "";
      this.keywordType = "all";
      this.startYmd = null;
      this.endYmd = null;
    },
    onUpdateAiDetecting(selectAiDetecing){
      //추후 ai서버와 연결 api메소드 추가
      console.log(selectAiDetecing)
    },
  },
  beforeDestroy() {
  },

};
</script>
